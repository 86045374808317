import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const styles = css`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.primaryBlue};
  text-decoration: none;
  background: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:visited {
    color: ${({ theme }) => theme.colors.primaryBlue};
  }

  ${({ white }) =>
    white &&
    css`
      color: white;
      &:visited {
        color: white;
      }
    `}
`;
const Anchor = styled.a`
  ${styles}
`;

export const SPAAnchor = styled(Link)`
  ${styles}
`;
export default Anchor;
