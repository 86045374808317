import React from "react";

import Button from "./Button";

function SubmitButton(props) {
  return <Button {...props} variant="blue" />;
}

SubmitButton.defaultProps = {
  type: "submit",
};

export default SubmitButton;
