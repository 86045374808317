import { css } from "styled-components";

const Variants = {
  blue: css`
    background: ${({ theme }) => theme.colors.primaryBlue};
    border: none;
  `,
  darkblue: css`
    background: ${({ theme }) => theme.colors.darkBlue};
    border: none;
  `,
  white: css`
    background: white;
    color: black;
    border: 1px solid ${({ theme }) => theme.colors.border};
    &:hover {
      background: ${({ theme }) => theme.colors.light};
    }
    &:disabled {
      color: ${({ theme }) => theme.colors.transparentDefaultText};
    }
  `,
  light: css`
    background: ${({ theme }) => theme.colors.light};
    color: ${({ theme }) => theme.colors.default};
    border: 1px solid ${({ theme }) => theme.colors.border};
    &:hover {
      background: white;
    }
    &:disabled {
      color: ${({ theme }) => theme.colors.transparentDefaultText};
    }
  `,
  green: css`
    background: ${({ theme }) => theme.colors.green};
    color: white;
    border: none;
    &:hover {
      background: ${({ theme }) => theme.colors.green};
    }
  `,
};

export default Variants;
