import styled from "styled-components";

const PageHeading = styled.h1`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: 36px;
  font-weight: normal;
  line-height: 1;
  letter-spacing: 0.5px;
  margin: 0;
`;

export default PageHeading;
