import React, { forwardRef } from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  border-right: 0;
  width: 100%;
  padding: 10px 17px;
  display: block;
  height: 50px;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  appearance: none;
  color: ${({ theme, disabled, error }) =>
    disabled
      ? "rgba(44, 45, 48, 0.75)"
      : error
      ? theme.colors.red
      : theme.colors.default};
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: 16px;
  line-height: 1.3;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.light : theme.colors.white};
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-color: ${({ theme, disabled, error }) =>
    disabled
      ? "rgba(44, 45, 48, 0.75)"
      : error
      ? theme.colors.red
      : theme.colors.border};
  border-radius: 5px;
  ${({ inline }) =>
    inline &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 0;
    `};

  ${({ stacked }) =>
    stacked &&
    css`
      border-radius: 0;

      &:not(:last-of-type):not(:focus) {
        border-bottom: 0;
      }

      &:first-of-type {
        border-radius: 5px 5px 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 5px 5px;
      }
    `};

  ${({ adjacent }) =>
    adjacent &&
    css`
      border-radius: 0;

      &:not(:last-of-type):not(:focus) {
        border-right-width: 0;
      }

      &:first-of-type {
        border-radius: 5px 0 0 5px;
      }

      &:last-of-type {
        border-radius: 0 5px 5px 0;
      }
    `};

  ${({ icon }) =>
    icon &&
    css`
      padding-right: 30px;
      background: url("${icon}");
      background-size: 15px;
      background-repeat: no-repeat;
      background-position: 10px center;
    `}

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.borderDark};
    border-color: ${({ theme, disabled, error }) =>
      disabled
        ? "rgba(44, 45, 48, 0.75)"
        : error
        ? theme.colors.red
        : theme.colors.borderDark};
  }
`;

const Input = styled.input`
  background: 0;
  border: none;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme, error }) =>
      error ? theme.colors.red : theme.colors.secondary};
  }
`;

const Label = styled.div`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.gray};
`;

Input.defaultProps = {
  type: "text",
  inline: false,
  stacked: false,
  adjacent: false,
};

const TextInputLabel = (
  { label, error, inline, adjacent, icon, stacked, value, ...rest },
  ref,
) => {
  return (
    <Wrapper
      inline={inline}
      error={error}
      adjacent={adjacent}
      icon={icon}
      stacked={stacked}
    >
      {label && value !== "" && <Label error={error}>{label}</Label>}
      <Input
        placeholder={label}
        ref={ref}
        error={error}
        value={value}
        {...rest}
      />
    </Wrapper>
  );
};

export default forwardRef(TextInputLabel);

