import React from "react";
import styled from "styled-components";

const StyledTable = styled.table`
  width: 100%;
  font-size: 13px;
  border: 0;
  border-collapse: collapse;
  background: white;
  margin-bottom: 20px;
`;

const Row = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`;

const HeaderRow = styled(Row)`
  background: white;
  border-bottom: 0;
`;

const FilterRow = styled(Row)`
  background: white;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border} !important;
`;

const Data = styled.td`
  padding: 15px;
`;

const FooterData = styled(Data)`
  background: white;
`;

/**
 * Header variant of the Data item
 */
const HeaderItem = styled(Data).attrs(() => ({
  as: "th",
}))`
  background: white;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSize.normal};
  text-align: left;
  white-space: nowrap;
  background: ${({ theme }) => theme.colors.light};
  padding: 7px 15px;
`;

const Table = ({ headerData, filterData, data, footer }) => {
  return (
    <StyledTable>
      {headerData && (
        <thead>
          <HeaderRow>
            {headerData.map((h, i) => (
              <HeaderItem key={i}>{h}</HeaderItem>
            ))}
          </HeaderRow>
        </thead>
      )}
      <tbody>
        {filterData && (
          <FilterRow>
            {filterData.map((component, i) => (
              <Data key={i}>{component}</Data>
            ))}
          </FilterRow>
        )}
        {data &&
          data.map((row, i) => (
            <Row key={i}>
              {row.map((component, j) => (
                <Data key={j}>{component}</Data>
              ))}
            </Row>
          ))}
        {footer && (
          <Row>
            <FooterData colSpan={headerData.length}>{footer}</FooterData>
          </Row>
        )}
      </tbody>
    </StyledTable>
  );
};

export default Table;
