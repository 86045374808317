import React from "react";
import styled from "styled-components";

const Input = styled.input`
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  height: 20px;
  width: 30px;
  border-radius: 12px;
  display: inline-block;
  position: relative;
  margin: 0;
  border: none;
  transition: all 0.2s ease;
  background: #cccccc;

  &:focus {
    outline: 0;
  }
  &:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: white;
    transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
  }
  &:checked {
    background: ${({ theme }) => theme.colors.primaryBlue};
  }
  &:checked:after {
    transform: translateX(10px);
  }
  &:disabled {
    cursor: default;
  }
`;

export default function Lightswitch({
  disabled,
  checked,
  onClick,
  onChange,
  id,
}) {
  return (
    <Input
      type="checkbox"
      onClick={onClick}
      onChange={onChange}
      disabled={disabled}
      defaultChecked={checked}
      id={id}
    />
  );
}
