import React from "react";
import styled, { css } from "styled-components";

import icon from "../../assets/checkmark.svg";

const Input = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;
`;

const Label = styled.label`
  cursor: pointer;
  display: inline-block;
  height: 23px;
  width: 23px;
  vertical-align: middle;
  background-color: white;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-sizing: border-box;
  ${({ checked }) =>
    checked &&
    css`
      background-image: url(${icon});
      background-size: 12px;
      background-position: center;
      background-color: ${({ theme }) => theme.colors.green};
      background-repeat: no-repeat;
      border: none;
    `}
`;
export default function Checkbox({ disabled, checked, onChange, id, onClick }) {
  return (
    <>
      <Input
        type="checkbox"
        onChange={onChange}
        disabled={disabled}
        checked={checked}
        id={id}
        readOnly={typeof onChange === "undefined"}
      />
      <Label
        onClick={onClick}
        htmlFor={id}
        checked={checked}
        id={id + "Label"}
      />
    </>
  );
}
