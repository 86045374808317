import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.normal};
  background: white;
  margin-bottom: 20px;
`;

const Row = styled.div`
  background: white;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
  padding: 10px 0;
`;

const FilterRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  padding: 20px 0;
  > * {
    width: 48%;
  }
`;

const Data = styled.div`
  margin: 10px 0;
`;

const FooterData = styled(Data)`
  background: white;
`;

const Header = styled.span`
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const MobileTable = ({ headerData, filterData, data, footer }) => {
  return (
    <Wrapper>
      {filterData && (
        <FilterRow>
          {filterData.map((component, i) =>
            component === null ? null : <div key={i}>{component}</div>,
          )}
        </FilterRow>
      )}
      {data &&
        data.map((row, i) => (
          <Row key={i}>
            {row.map((component, j) => (
              <Data key={j}>
                <Header>{headerData[j]}:</Header> {component}
              </Data>
            ))}
          </Row>
        ))}
      {footer && <FooterData>{footer}</FooterData>}
    </Wrapper>
  );
};

export default MobileTable;
