import styled from "styled-components";

const H1 = styled.h1`
  font-size: 26px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-weight: normal;
  margin: 0;
`;

const H2 = styled.h2`
  font-size: 21px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-weight: normal;
  margin: 0;
`;

const H3 = styled.h3`
  font-size: 17px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-weight: normal;
  margin: 0;
`;

const H4 = styled.h4`
  font-size: 13px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: normal;
  margin: 0;
`;

export { H1, H2, H3, H4 };
