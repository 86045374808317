import React from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.button`
  cursor: pointer;
  display: block;
  background: none;
  padding: 0;
  outline: none;
  border: none;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  box-sizing: border-box;

  ${({ circle }) =>
    circle &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.border};
      border-radius: 50%;
      position: relative;
      background: white;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 35%;
      }
    `};
`;

export default function CloseButton({
  close,
  fill = "#ffffff",
  height,
  width,
  id,
  circle,
  ...rest
}) {
  return (
    <Wrapper
      onClick={close}
      role="button"
      circle={circle}
      width={width}
      height={height}
      id={id}
      {...rest}
    >
      <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.5073 1.8208L15.6865 0L8.75781 6.92871L1.8291 0L0.00830078 1.8208L6.93701 8.74951L0.00830078 15.6782L1.8291 17.499L8.75781 10.5703L15.6865 17.499L17.5073 15.6782L10.5786 8.74951L17.5073 1.8208Z"
          fill={fill}
        />
      </svg>
    </Wrapper>
  );
}
