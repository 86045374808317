import React from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.button`
  cursor: pointer;
  display: block;
  background: none;
  padding: 0;
  outline: none;
  border: none;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  box-sizing: border-box;

  ${({ circle }) =>
    circle &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.border};
      border-radius: 50%;
      position: relative;
      background: white;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 35%;
      }
    `};
`;

export default function BackButton({
  onClick,
  fill = "#ffffff",
  height,
  width,
  id,
  circle,
}) {
  return (
    <Wrapper
      onClick={onClick}
      role="button"
      circle={circle}
      width={width}
      height={height}
    >
      <svg
        width="9"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id={id}
      >
        <path
          d="M7.01172 14.0234L8.11523 12.9199L2.20703 7.01172L8.11523 1.10352L7.01172 0L0 7.01172L7.01172 14.0234Z"
          fill={fill}
        />
      </svg>
    </Wrapper>
  );
}
